import "./App.css";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Je teste docker</p>
        <img
          src="https://blog.lecacheur.com/wp-content/uploads/2014/10/docker.png"
          alt="docker"
        />
      </header>
    </div>
  );
}

export default App;
